import React from "react";
import Button from "@mui/material/Button";
import AnalyticsContext from ".";

const ManageConsentButton = ({ toggleActive = () => {} }) => {
  const { setConsentDialogOpen } = React.useContext(AnalyticsContext);
  return (
    <Button
      color="secondary"
      className="border-secondary"
      onClick={() => {
        setConsentDialogOpen(true);
        toggleActive();
      }}
    >
      Cookie Preferences
    </Button>
  );
};

export default ManageConsentButton;
