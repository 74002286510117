import React from "react";
import createCache from "@emotion/cache";
import createEmotionServer from "@emotion/server/create-instance";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider, useMediaQuery } from "@mui/material";
// import { CssBaseline } from "@mui/material";
import themes from "@/styles/themes";
import { useLocalStorage } from "usehooks-ts";
import { ToggleButtonGroup } from "@mui/material";
import { ToggleButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import Head from "next/head";

const ThemeContext = React.createContext();

export const MultiThemeProvider = ({ children }) => {
  const [theme, setTheme] = React.useState("light-theme");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersHighContrast = useMediaQuery("(prefers-contrast: more)");
  const [themePreference, setThemePreference] = useLocalStorage(
    "themePreference",
    "auto",
  );
  const [systemPreference, setSystemPreference] = React.useState("light-theme");

  // React.useEffect(() => {
  //   setThemePreference(themePreference);
  // }, []);

  // Monitor system preference
  React.useEffect(() => {
    if (prefersDarkMode) {
      // console.log("Prefers Dark Mode");
      setSystemPreference("dark-theme");
    } else if (prefersHighContrast) {
      // console.log("Prefers High Contrast");
      setSystemPreference("high-contrast-theme");
    } else {
      // console.log("Prefers Light Mode");
      setSystemPreference("light-theme");
    }
  }, [prefersDarkMode, prefersHighContrast]);

  // Monitor theme preference and system preference and update theme accordingly
  React.useEffect(() => {
    if (themePreference === "auto") {
      // console.log("Setting Theme: " + systemPreference);
      setTheme(systemPreference);
    } else {
      // console.log("Setting Theme: " + themePreference);
      setTheme(themePreference);
    }
    document.getElementsByTagName("html")[0].className = theme;
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", themes[theme].theme.palette.background.default);
  }, [themePreference, systemPreference, theme]);

  const data = {
    themePreference: themePreference,
    setThemePreference: setThemePreference,
  };

  return (
    <CacheProvider value={createCache({ key: "css", prepend: true })}>
      <ThemeContext.Provider value={data}>
        <ThemeProvider theme={themes[theme].theme}>
          {/* <CssBaseline /> */}
          {children}
        </ThemeProvider>
      </ThemeContext.Provider>
    </CacheProvider>
  );
};

const ThemeSelector = () => {
  const { themePreference, setThemePreference } =
    React.useContext(ThemeContext);

  const buttonData = Object.keys(themes).map((key) => {
    return {
      value: key,
      name: themes[key].name,
      icon: themes[key].icon,
      theme: themes[key].theme,
    };
  });

  const handleChange = (event) => {
    setThemePreference(event.target.value);
  };

  return (
    <ToggleButtonGroup
      color="secondary"
      value={themePreference}
      exclusive
      onChange={handleChange}
      aria-label="Select Theme"
    >
      {buttonData.map((button) => (
        <Tooltip key={button.value} value={button.value} title={button.name}>
          <ToggleButton value={button.value}>
            <button.icon value={button.value} className="pointer-events-none" />
          </ToggleButton>
        </Tooltip>
      ))}
      Testing
    </ToggleButtonGroup>
  );
};

// function createEmotionCache() {
//   return createCache({ key: "css", prepend: true });
// }

// export { MultiThemeProvider };
export default ThemeSelector;

export function getEmotionStyleTags(ctx, initialProps) {
  const originalRenderPage = ctx.renderPage;

  const cache = createCache({ key: "css", prepend: true });
  const { extractCriticalToChunks } = createEmotionServer(cache);

  ctx.renderPage = () =>
    originalRenderPage({
      enhanceApp: (App) =>
        function EnhanceApp(props) {
          return <App emotionCache={cache} {...props} />;
        },
    });

  const emotionStyles = extractCriticalToChunks(initialProps.html);
  const emotionStyleTags = emotionStyles.styles.map((style) => (
    <style
      data-emotion={`${style.key} ${style.ids.join(" ")}`}
      key={style.key}
      dangerouslySetInnerHTML={{ __html: style.css }}
    />
  ));

  return emotionStyleTags;
}

// export const getMuiInitialProps = async (ctx) => {
//   const initialProps = await Document.getInitialProps(ctx);
//   const emotionStyleTags = getEmotionStyleTags(ctx, initialProps);

//   return {
//     ...initialProps,
//     emotionStyleTags,
//   };
// };
