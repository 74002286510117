import React from "react";
import { Menu, Transition } from "@headlessui/react";
import NavLinks from "./nav-links";
import Link from "next/link";
import { useRouter } from "next/router";
import ThemeSelecctor from "@/utils/multi-theme-provider";
import ManageConsentButton from "@/utils/analytics-provider/manage-consent-button";

const NavMenu = () => {
  return (
    <Menu as="nav" className="relative flex items-center gap-2 text-left">
      {({ open, close }) => (
        <>
          {/* This div is just to make tailwind generate the classes used conditaionally below. */}
          {/* <div className="sr-only sm:block sm:hidden md:block md:hidden lg:block lg:hidden xl:block xl:hidden" /> */}
          {NavLinks.map((link) => (
            // const current = link.href === router.pathname;
            // const showLink = link.showInHeaderFrom === "sm" || current;
            <NavItem
              key={link.label}
              // current={current}
              // showLink={showLink}
              link={link}
            />
          ))}
          <Menu.Button className="flex h-10 w-10 items-center justify-center rounded-full hover:bg-primary/20 focus:outline-none">
            <span className="sr-only">Open Menu</span>
            {/* <svg
              className={`${
                open ? "rotate-90" : ""
              } h-6 w-6 fill-auto-black/80 transition-all`}
              viewBox="0 0 24 24"
            >
              <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z" />
            </svg> */}
            <svg
              className="h-6 w-6"
              id="w_hamburger_icon"
              viewBox="0 0 500 398"
            >
              <style>
                {`
                .st0{fill:#FFFFFF;}
                .st1{opacity:0.7;fill:#B00093;}
                .st2{opacity:0.7;fill:#F46200;}
                .st3{opacity:0.7;fill:#2DBF00;}
                .st4{opacity:0.7;fill:#0071D6;}
                `}
              </style>
              <path
                id="White_Green"
                className={`st0 transition-transform ease-out ${
                  open ? "-translate-y-28 translate-x-28 rotate-45" : ""
                } origin-center`}
                d="M454.1,309.3l-408.6,0c-23.1,0-41.9,18.8-41.9,41.9v0c0,23.1,18.8,41.9,41.9,41.9l408.6,0
	c23.1,0,41.9-18.8,41.9-41.9v0C496,328,477.2,309.3,454.1,309.3z"
              />
              <path
                id="White_Blue"
                className={`st0 transition-transform ease-out ${
                  open ? "translate-x-28 translate-y-28 -rotate-45" : ""
                } origin-center`}
                d="M454.1,4.1l-408.6,0C22.3,4.1,3.6,22.9,3.6,46v0c0,23.1,18.8,41.9,41.9,41.9l408.6,0
	c23.1,0,41.9-18.8,41.9-41.9v0C496,22.9,477.2,4.1,454.1,4.1z"
              />
              <path
                id="Green_Green"
                className={`st1 transition-transform ease-out ${
                  open ? "-translate-y-28 translate-x-28 rotate-45" : ""
                } origin-center`}
                d="M454.1,309.3l-408.6,0c-23.1,0-41.9,18.8-41.9,41.9v0c0,23.1,18.8,41.9,41.9,41.9l408.6,0
	c23.1,0,41.9-18.8,41.9-41.9v0C496,328,477.2,309.3,454.1,309.3z"
              />
              <path
                id="White_Orange_Back"
                className={`st0 transition-all ease-out ${
                  open
                    ? "-translate-x-12 translate-y-14 -rotate-45 opacity-0"
                    : "opacity-50"
                } origin-center`}
                d="M454.1,156.7l-254.6,0c-23.1,0-41.9,18.8-41.9,41.9v0c0,23.1,18.8,41.9,41.9,41.9l254.6,0
	c23.1,0,41.9-18.8,41.9-41.9v0C496,175.5,477.2,156.7,454.1,156.7z"
              />
              <path
                id="White_Magenta"
                className={`st0 transition-transform ease-out ${
                  open ? "translate-x-14 translate-y-12 rotate-45" : ""
                } origin-center`}
                d="M300.1,156.7l-254.6,0c-23.1,0-41.9,18.8-41.9,41.9v0c0,23.1,18.8,41.9,41.9,41.9l254.6,0
	c23.1,0,41.9-18.8,41.9-41.9v0C342,175.5,323.2,156.7,300.1,156.7z"
              />
              <path
                id="Magenta_Magenta"
                className={`st2 transition-transform ease-out ${
                  open ? "translate-x-14 translate-y-12 rotate-45" : ""
                } origin-center`}
                d="M300.1,156.7l-254.6,0c-23.1,0-41.9,18.8-41.9,41.9v0c0,23.1,18.8,41.9,41.9,41.9l254.6,0
	c23.1,0,41.9-18.8,41.9-41.9v0C342,175.5,323.2,156.7,300.1,156.7z"
              />
              <path
                id="Blue_Blue"
                className={`st4 transition-transform ease-out ${
                  open ? "translate-x-28 translate-y-28 -rotate-45" : ""
                } origin-center`}
                d="M454.1,4.1l-408.6,0C22.3,4.1,3.6,22.9,3.6,46v0c0,23.1,18.8,41.9,41.9,41.9l408.6,0
	c23.1,0,41.9-18.8,41.9-41.9v0C496,22.9,477.2,4.1,454.1,4.1z"
              />
              <path
                id="White_Orange_Middle"
                className={`st0 transition-all ease-out ${
                  open
                    ? "-translate-x-12 translate-y-14 -rotate-45 opacity-50"
                    : "opacity-0"
                } origin-center`}
                d="M454.1,156.7l-254.6,0c-23.1,0-41.9,18.8-41.9,41.9v0c0,23.1,18.8,41.9,41.9,41.9l254.6,0
	c23.1,0,41.9-18.8,41.9-41.9v0C496,175.5,477.2,156.7,454.1,156.7z"
              />
              <path
                id="Orange_Orange"
                className={`st3 transition-transform ease-out ${
                  open ? "-translate-x-12 translate-y-14 -rotate-45" : ""
                } origin-center`}
                d="M454.1,156.7l-254.6,0c-23.1,0-41.9,18.8-41.9,41.9v0c0,23.1,18.8,41.9,41.9,41.9l254.6,0
	c23.1,0,41.9-18.8,41.9-41.9v0C496,175.5,477.2,156.7,454.1,156.7z"
              />
            </svg>
          </Menu.Button>
          <Transition
            className="-ml-2"
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="absolute mt-6 flex w-52 origin-top-right flex-col gap-2 divide-y divide-gray-100 rounded-md bg-auto-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ltr:right-0 rtl:left-0">
              <Menu.Item
                as="div"
                className="flex w-full cursor-pointer items-center justify-center"
              >
                <ThemeSelecctor />
              </Menu.Item>
              {/* <Menu.Item as="div" className="w-full cursor-pointer">
                {({ active }) => (
                  <div
                    className={`w-full rounded-md p-2 ${
                      active ? "bg-primary/20" : ""
                    }`}
                    onClick={() => {
                      const themes = [
                        "light-theme",
                        "dark-theme",
                        "high-contrast-theme",
                      ];
                      // document.documentElement.setAttribute(
                      //   "data-theme",
                      //   document.documentElement.getAttribute("data-theme") ===
                      //     "dark"
                      //     ? "light"
                      //     : "dark"
                      // );
                      document.documentElement.classList.remove(...themes);
                      document.documentElement.classList.add("light-theme");
                    }}
                  >
                    Light Theme
                  </div>
                )}
              </Menu.Item>
              <Menu.Item as="div" className="w-full cursor-pointer">
                {({ active }) => (
                  <div
                    className={`w-full rounded-md p-2 ${
                      active ? "bg-primary/20" : ""
                    }`}
                    onClick={() => {
                      const themes = [
                        "light-theme",
                        "dark-theme",
                        "high-contrast-theme",
                      ];
                      // document.documentElement.setAttribute(
                      //   "data-theme",
                      //   document.documentElement.getAttribute("data-theme") ===
                      //     "dark"
                      //     ? "light"
                      //     : "dark"
                      // );
                      document.documentElement.classList.remove(...themes);
                      document.documentElement.classList.add("dark-theme");
                    }}
                  >
                    Dark Theme
                  </div>
                )}
              </Menu.Item> */}
              {NavLinks.map((link) => (
                <Menu.Item
                  as={Link}
                  key={link.label}
                  className={`block w-full cursor-pointer md:hidden`}
                  href={link.href}
                  onClick={close}
                >
                  {({ active }) => (
                    <div
                      className={`w-full rounded-md p-2 ${
                        active ? "bg-primary/20" : ""
                      }`}
                    >
                      {link.label}
                    </div>
                  )}
                </Menu.Item>
              ))}
              <ManageConsentButton toggleActive={close} />
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default NavMenu;

// interface NavItemProps extends React.HTMLProps<HTMLDivElement> {
//   link: NavLink;
// }

function NavItem({ link }) {
  const router = useRouter();
  const [current, setCurrent] = React.useState(false);
  const [showLink, setShowLink] = React.useState(false);

  React.useEffect(() => {
    const current =
      link.href === router.pathname ||
      (link.href === "/blog" && router.pathname.startsWith("/blog"));
    setCurrent(current);
    const checkShowLink = () => {
      setShowLink(
        (window.innerWidth >= link.minWidth &&
          window.screen.width >= link.minWidth) ||
          (current && window.innerWidth >= 600 && window.screen.width >= 600),
      );
    };
    checkShowLink();
    window.addEventListener("resize", checkShowLink);
    return () => {
      window.removeEventListener("resize", checkShowLink);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  return (
    <Link
      href={link.href}
      className="relative ml-0 flex max-w-xs items-center justify-center overflow-hidden rounded-full bg-primary/30 px-4 py-1.5 text-lg will-change-auto hover:bg-primary/20 max-md:hidden"
    >
      {link.label}
      <Transition
        appear={true}
        show={current}
        className="absolute bottom-0 h-1 bg-secondary"
        enter="transition-all duration-1000 transition-cos"
        enterFrom="left-1/2 right-1/2"
        enterTo="left-0 right-0"
        leave="transition-all duration-300 transition-cos"
        leaveFrom="left-0 right-0"
        leaveTo="left-1/2 right-1/2"
      />
    </Link>
  );
}
