// Next-SEO Configuration. Used by next-seo package.

const config = {
  titleTemplate: "%s | waleed.dev",
  // facebook: { appId: "swalex" },
  openGraph: {
    type: "website",
    locale: "en_US",
    url: "https://waleed.dev/",
    siteName: "waleed.dev",
    urlTemplate: "https://waleed.dev/%s",
  },
  twitter: {
    handle: "@dudesalama",
    site: "@dudesalama",
    cardType: "summary_large_image",
  },
};

const socialProfile = {
  type: "Organization",
  name: "Waleed Salama",
  url: "https://waleed.dev/",
  sameAs: [
    // "https://www.facebook.com/EloqLanguages",
    // "https://www.instagram.com/eloquence.languagelearning/",
    // "https://www.tiktok.com/@eloquence.lang.learning?_t=8eSZnzpTRPW&_r=1",
  ],
};

export { config, socialProfile };
