import Link from "next/link";
import React from "react";

const Footer = () => {
  const [year, setYear] = React.useState(2024);

  React.useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  return (
    <footer className="mt-auto bg-primary-800 text-white/80 dark-theme:bg-black high-contrast-theme:text-white ">
      <div className="container grid grid-cols-1 gap-4 py-12 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3">
        <div className="col-span-1 sm:col-span-2 md:col-span-1 lg:col-span-1">
          © {year} Waleed Salama
        </div>
        <div className="">
          <Link href="/privacy" className="hover:text-white">
            Privacy Policy
          </Link>
        </div>
        <Link className="hidden hover:text-white sm:block" href="/trail-game">
          👻 Trail Game
        </Link>
        <div className="flex gap-2">
          <a
            href="https://x.com/DudeSalama"
            target="_blank"
            rel="noopener noreferrer"
            className="flex h-8 w-8 items-center justify-center rounded-full border border-white/80 text-xl transition-colors duration-150 hover:border-white hover:bg-white hover:text-primary-800"
          >
            𝕏
          </a>
          <a
            href="https://github.com/waleed-salama"
            target="_blank"
            rel="noopener noreferrer"
            className="flex h-8 w-8 items-center justify-center rounded-full border-primary-50 transition-colors duration-150 hover:border-primary-500 hover:bg-white hover:text-primary-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="h-8 w-8 fill-none"
            >
              <path
                d="M6.51734 17.1132C6.91177 17.6905 8.10883 18.9228 9.74168 19.2333M9.86428 22C8.83582 21.8306 2 19.6057 2 12.0926C2 5.06329 8.0019 2 12.0008 2C15.9996 2 22 5.06329 22 12.0926C22 19.6057 15.1642 21.8306 14.1357 22C14.1357 22 13.9267 18.5826 14.0487 17.9969C14.1706 17.4113 13.7552 16.4688 13.7552 16.4688C14.7262 16.1055 16.2043 15.5847 16.7001 14.1874C17.0848 13.1032 17.3268 11.5288 16.2508 10.0489C16.2508 10.0489 16.5318 7.65809 15.9996 7.56548C15.4675 7.47287 13.8998 8.51192 13.8998 8.51192C13.4432 8.38248 12.4243 8.13476 12.0018 8.17939C11.5792 8.13476 10.5568 8.38248 10.1002 8.51192C10.1002 8.51192 8.53249 7.47287 8.00036 7.56548C7.46823 7.65809 7.74917 10.0489 7.74917 10.0489C6.67316 11.5288 6.91516 13.1032 7.2999 14.1874C7.79575 15.5847 9.27384 16.1055 10.2448 16.4688C10.2448 16.4688 9.82944 17.4113 9.95135 17.9969C10.0733 18.5826 9.86428 22 9.86428 22Z"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
