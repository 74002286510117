import { Comfortaa as HeaderFont, Roboto as BodyFont } from "next/font/google";

import React from "react";
import Head from "next/head";
// import { useRouter } from "next/router";
// import LeftSidebar from "@/components/Layout/LeftSidebar";
import AppBar from "./appbar";
import Footer from "./footer";
// import ScrollToTop from "@/components/Layout/ScrollToTop";
// import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@mui/material";
// import ControlPanelModal from "@/componentsControlPanelModal";
// import { motion, AnimatePresence } from "framer-motion";
// import { NextSeo } from "next-seo";

const headerFont = HeaderFont({
  subsets: ["latin"],
  //weight: //variable font; doesn't require weight
  display: "swap",
  variable: "--font-header",
});

const bodyFont = BodyFont({
  subsets: ["latin"],
  weight: ["100", "300", "400", "500", "700", "900"],
  display: "swap",
  variable: "--font-body",
});

// type LayoutProps = {
//   defaultSidebarVisible?: boolean;
//   children?: React.ReactNode;
// };

const Layout = ({ defaultSidebarVisible = false, children }) => {
  // const theme = useTheme();

  // const router = useRouter();
  const [sidebarVisible, setSidebarVisible] = React.useState(
    defaultSidebarVisible,
  );

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // const isMd = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  // React.useEffect(() => {
  //   if (defaultSidebarOpen) {
  //     if (isMd) {
  //       setActive(false);
  //     } else {
  //       setActive(true);
  //     }
  //   }
  // }, [isMd]);

  // const [darkMode, setDarkMode] = useState(false);

  // useEffect(() => {
  //   const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

  //   // Listen for changes to the color scheme preference
  //   const handleChange = (e) => {
  //     // if it's dark -> set dark theme_color
  //     // console.log(e.matches);
  //     setDarkMode(e.matches);
  //     // theme.palette.text.primary = e.matches
  //     //   ? "hsla(0, 0%, 100%, 80%)"
  //     //   : "hsla(0, 0%, 0%, 80%)";
  //     // theme.palette.text.secondary = e.matches
  //     //   ? "hsla(0, 0%, 100%, 60%)"
  //     //   : "hsla(0, 0%, 0%, 60%)";
  //     // if (e.matches) {
  //     //   document
  //     //     .querySelector('meta[name="theme-color"]')
  //     //     .setAttribute("content", "#6b00b3");
  //     // }
  //     // // if it's light -> set light theme_color
  //     // else {
  //     //   document
  //     //     .querySelector('meta[name="theme-color"]')
  //     //     .setAttribute("content", "#a31aff");
  //     // }
  //   };
  //   handleChange(mediaQuery);
  //   mediaQuery.addEventListener("change", handleChange);

  //   return () => {
  //     mediaQuery.removeEventListener("change", handleChange);
  //   };
  // }, []);

  // const motionVariants = {
  //   main: {
  //     hidden: {
  //       opacity: 0,
  //       // x: -50,
  //       transition: {
  //         duration: 0.2,
  //       },
  //     },
  //     visible: {
  //       opacity: 1,
  //       // x: 0,
  //       transition: {
  //         duration: 0.2,
  //       },
  //     },
  //   },
  // };

  return (
    <>
      {/* <NextSeo
        themeColor={theme.palette.appBar.main}
        backgroundColor={theme.palette.background.default}
      /> */}
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <div
        className={`flex min-h-screen flex-col ${
          defaultSidebarVisible ? " default-sidebar-open" : ""
        }${sidebarVisible ? " sidebar-visible" : ""} ${headerFont.variable} ${
          bodyFont.variable
        } high-contrast-theme:font-medium high-contrast-theme:text-black high-contrast-theme:antialiased`}
      >
        <AppBar toggleSidebar={toggleSidebar} />
        {/* <LeftSidebar
          toggleSidebar={toggleSidebar}
          active={active}
          enableBackdrop={!defaultSidebarOpen || isMd}
          defaultSidebarOpen={defaultSidebarOpen}
        /> */}
        <main className="page-content font-body text-auto-black/80">
          {/* <AnimatePresence mode="wait">
            <motion.main
              key={useRouter().pathname}
              variants={motionVariants.main}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ type: "inertia" }}
            > */}
          {children}
          {/* </motion.main>
          </AnimatePresence> */}
        </main>
        <Footer />

        {/* ScrollToTop */}
        {/* <ScrollToTop /> */}

        {/* <ControlPanelModal /> */}
      </div>
    </>
  );
};

export default Layout;
