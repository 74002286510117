import Layout from "@/components/layout";
import "@/styles/globals.scss";
import { AnalyticsProvider } from "@/utils/analytics-provider";
import { MultiThemeProvider } from "@/utils/multi-theme-provider";
import NextSeoWrapper from "@/utils/next-seo-wrapper";
import { SnackbarProvider } from "notistack";
import React from "react";

const MyApp = ({ Component, pageProps }) => {
  // This is a hack to fix the issue with the hash links not working properly, covered by navbar.
  React.useEffect(() => {
    if (location.hash) {
      const timeout = setTimeout(() => scrollBy(0, -50), 0);
    }

    var shiftWindow = function () {
      scrollBy(0, -50);
    };
    window.addEventListener("hashchange", shiftWindow);

    return () => {
      try {
        clearTimeout(timeout);
      } catch (e) {}
      window.removeEventListener("hashchange", shiftWindow);
    };
  }, []);

  return (
    <MultiThemeProvider>
      <AnalyticsProvider>
        <SnackbarProvider maxSnack={3} autoHideDuration={6000}>
          <NextSeoWrapper />
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </SnackbarProvider>
      </AnalyticsProvider>
    </MultiThemeProvider>
  );
};

export default MyApp;
