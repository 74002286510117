import * as React from "react";
import Image from "next/image";
import * as logo from "@/public/W-Logo.svg";
import NavMenu from "./nav-menu";
import Link from "next/link";
// import Link from "next/link";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import Stack from "@mui/material/Stack";
// import Logo from "@/components/Layout/Logo";
// import { SvgIcon } from "@mui/material";
// import SearchForm from "./SearchForm";
// import Email from "./Email";
// import Notification from "./Notification";
// import Profile from "./Profile";
// import Tooltip from "@mui/material/Tooltip";
// import CurrentDate from "./CurrentDate";

// import { useMsal } from "@azure/msal-react";
// import { b2cPolicies } from "@/utils/authConfig";

// import { TokenContext } from "@/utils/TokenProvider";
// import AnalyticsContext from "@/utils/AnalyticsProvider";
// import { motion } from "framer-motion";
// import { useCookies } from "react-cookie";

// type AppBarProps = {
//   toggleSidebar: () => void;
// };

const AppBar = ({ toggleSidebar }) => {
  // const { isAuthenticated, userId } = React.useContext(TokenContext);
  // const { instance: msalInstance } = useMsal();
  // const analytics = React.useContext(AnalyticsContext);

  return (
    <header className="sticky top-0 z-app-bar w-screen bg-gradient-to-t from-html-background/40 to-html-background py-3 font-body font-light text-auto-black/80 backdrop-blur high-contrast-theme:font-medium">
      <nav className="container flex items-center justify-between">
        {/* <AppBar
          color="inherit"
          sx={{
            backgroundColor: "appBar.main", //fff
            // backgroundImage:
            //   "conic-gradient(from 183deg at 35% 150%, var(--primary-dark) 0%, var(--primary-dark) 20%, var(--primary) 35%, var(--primary) 50%, var(--primary-light) 65%, var(--secondary) 71%, var(--accent2) 75%, var(--primary) 100%)",
            // boxShadow: "0px 4px 20px rgba(47, 143, 232, 0.07)",
            py: "6px",
            // mb: "10px",
            position: "sticky",
          }}
          className="top-navbar-for-dark"
          component={motion.div}
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
        > */}
        <Link
          className="flex items-center gap-4"
          href="/"
          aria-label="Go to waleed.dev Home"
        >
          <Image src={logo} alt="W Logo" height={40} />
          <span className="text-2xl font-thin high-contrast-theme:font-normal">
            waleed.dev
          </span>
        </Link>
        <NavMenu />
        {/* <Toolbar className="relative h-10">
            <Tooltip title="Hide/Show" arrow>
              <IconButton
                size="sm"
                edge="start"
                color="inherit"
                onClick={toggleSidebar}
              >
                <SvgIcon style={{ color: "white" }}>
                  <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z" />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center pointer-events-none row">
              <Link className="pointer-events-auto" href="/" aria-label="Home">
                <Logo height="40px" />
              </Link>
            </div>
            {/* Search form */}
        {/* <SearchForm /> */}

        {/* <div className="flex-1" /> */}

        {/* <Stack direction="row" spacing={{ xs: 0.5, md: 2 }}> */}
        {/* CurrentDate */}
        {/* <CurrentDate /> */}
        {/* Email */}
        {/* <Email /> */}
        {/* {!isAuthenticated && ( */}
        {/* <NavBarBookPlacementButton /> */}
        {/* )} */}
        {/* Notification */}
        {/* <Notification /> */}
        {/* Profile */}
        {/* <Profile /> */}
        {/* </Stack> */}
        {/* </Toolbar> */}
        {/* </AppBar> */}
      </nav>
    </header>
  );
};

export default AppBar;
