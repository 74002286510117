import React from "react";
import dynamic from "next/dynamic";

// For Analytics Provider
import ReactGA from "react-ga4"; // Requires: "react-ga4": "^2.1.0"
import { useRouter } from "next/router";
import { useLocalStorage } from "usehooks-ts";

import ipapi from "ipapi.co";

import { requireConsent, consentValidityDays } from "./config";
const googleAnalyticsTrackingID = process.env.GA_TRACKING_ID;
const googleTagID = process.env.G_TAG_ID ?? null;

const AnalyticsContext = React.createContext();

const AnalyticsProvider = ({ children }) => {
  const ConsentBanner = dynamic(() => import("./consent-banner"));
  const TagManager = googleTagID ? require("react-gtm-module") : null; // Requires: "react-gtm-module": "^2.0.11"

  const [isEu, setIsEu] = React.useState(true);
  const [euChecked, setEuChecked] = React.useState(false);
  const [requireConsentInteraction, setRequireConsentInteraction] =
    React.useState(false);
  const [consentDialogOpen, setConsentDialogOpen] = React.useState(false);
  const [consent, setConsent] = useLocalStorage(
    "consent",
    requireConsent === "none"
      ? {
          analytics_storage: "granted",
          ad_storage: "granted",
        }
      : {
          analytics_storage: "denied",
          ad_storage: "denied",
        },
  );
  const [consentDate, setConsentDate] = useLocalStorage("consentDate", null);
  const [consentValid, setConsentValid] = React.useState(false);

  // Initialize Consent Validity
  React.useEffect(() => {
    consentDate &&
    new Date() - new Date(consentDate) <=
      consentValidityDays * 24 * 60 * 60 * 1000
      ? setConsentValid(true)
      : setConsentValid(false);
  }, [consent, consentDate]);

  // EU Check
  React.useEffect(() => {
    if (requireConsent === "eu") {
      ipapi.location((loc) => {
        setIsEu(loc.in_eu);
        setEuChecked(true);
      });
    }
    // Runs only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialize Consent Interaction if required
  React.useEffect(() => {
    if (requireConsent === "all") {
      setRequireConsentInteraction(true);
    } else if (requireConsent === "eu") {
      // console.log(`isEu: ${isEu}`);
      if (euChecked && isEu) {
        setRequireConsentInteraction(true);
      } else if (euChecked && !isEu) {
        if (!consentDate) {
          setConsent({
            analytics_storage: "granted",
            ad_storage: "granted",
          });
          setRequireConsentInteraction(false);
        } else if (consentDate) {
          setRequireConsentInteraction(true);
        }
      }
    } else if (requireConsent === "none") {
      setConsent({
        analytics_storage: "granted",
        ad_storage: "granted",
      });
      setRequireConsentInteraction(false);
    }
  }, [consentDate, euChecked, isEu, setConsent]);

  // Initialize Consent Dialog
  React.useEffect(() => {
    // console.log(`requireConsentInteraction: ${requireConsentInteraction}`);
    // If first visit
    if (requireConsentInteraction && !consentDate) {
      setConsentDialogOpen(true);
    }
    // If consent was granted, and expired
    else if (
      requireConsentInteraction &&
      JSON.stringify(consent) !==
        JSON.stringify({
          analytics_storage: "denied",
          ad_storage: "denied",
        }) &&
      !(
        consentDate &&
        new Date() - new Date(consentDate) <=
          consentValidityDays * 24 * 60 * 60 * 1000
      )
    ) {
      setConsentDialogOpen(true);
    }
    // Otherwise, close the dialog
    else setConsentDialogOpen(false);
  }, [consent, consentDate, requireConsentInteraction]);

  // Initialize Google Analytics
  React.useEffect(() => {
    if (googleAnalyticsTrackingID && ReactGA) {
      console.debug("Initializing Google Analytics");
      // Any changes here must be reflected in csp-header.js as it will affect the script being injected by ReactGA4.
      // Initialize Google Analytics
      if (googleAnalyticsTrackingID) {
        ReactGA.gtag("consent", "default", {
          analytics_storage: "denied",
          ad_storage: "denied",
        });
        ReactGA.initialize([
          {
            trackingId: googleAnalyticsTrackingID,
            dataLayer: window.gaDataLayer,
            dataLayerName: "gaDataLayer",
          },
        ]);
      }

      // Return resetting function
      return () => {
        ReactGA.reset();
      };
    }
  }, []);

  // Initialize Google Tag Manager
  React.useEffect(() => {
    if (googleTagID && TagManager) {
      console.debug("Initializing Google Tag Manager");
      // Any changes here must be reflected in csp-header.js
      TagManager.initialize({
        gtmId: googleTagID,
        dataLayer: window.gtmDataLayer,
        dataLayerName: "gtmDataLayer",
      });
    }
  }, [TagManager, consent, isEu, consentValid]);

  // Monitor changes in consent, and update Google Analytics and Google Tag Manager
  React.useEffect(() => {
    if (googleAnalyticsTrackingID && ReactGA) {
      console.debug("Updating Google Analytics");
      ReactGA.gtag("consent", "update", consent);
    }
    if (googleTagID && TagManager) {
      console.debug("Updating Google Tag Manager");
      TagManager.gtm("consent", "update", consent);
    }
  }, [consent, TagManager]);

  const router = useRouter();

  React.useEffect(() => {
    pageView();
    // console.log(router.asPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const action = (event_name, event_label) => {
    const report = {
      action: event_name,
      category: "user_interaction",
      label: event_label,
    };

    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.event(report);
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            event: report.action,
            ...report,
          },
        });
      } catch (error) {}
    }
  };

  const conversion = (event_name, event_label, value) => {
    const report = {
      action: event_name,
      category: "conversion",
      label: event_label,
      value: value,
    };

    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.event(report);
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            event: report.action,
            ...report,
          },
        });
      } catch (error) {}
    }
  };

  const success = (event_name, event_label) => {
    const report = {
      action: event_name,
      category: "operation_success",
      label: event_label,
    };

    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.event(report);
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            event: report.action,
            ...report,
          },
        });
      } catch (error) {}
    }
  };

  const error = (event_name, event_label) => {
    const report = {
      action: event_name,
      category: "error",
      label: event_label,
    };

    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.event(report);
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            event: report.action,
            ...report,
          },
        });
      } catch (error) {}
    }
  };

  const pageView = () => {
    const report = {
      action: "page_view",
      category: "page_view",
      label: router.pathname,
    };

    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.send({
          hitType: "pageview",
          page: router.pathname,
        });
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            event: report.action,
            ...report,
          },
        });
      } catch (error) {}
    }
  };

  const newUser = (value) => {
    const report = {
      action: "new_user",
      category: "new_user",
      label: "new_user",
      value: value ?? 1.0,
    };

    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.event(report);
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            event: report.action,
            ...report,
          },
        });
      } catch (error) {}
    }
  };

  const setUserId = (user_id) => {
    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.set({ user_id: user_id });
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            user_id: user_id,
          },
        });
      } catch (error) {}
    }
  };

  const context = {
    action,
    conversion,
    success,
    error,
    pageView,
    newUser,
    setUserId,
    consent,
    setConsent,
    consentDialogOpen,
    setConsentDialogOpen,
  };

  return (
    <AnalyticsContext.Provider value={context}>
      <ConsentBanner
        consent={consent}
        setConsent={setConsent}
        consentDialogOpen={consentDialogOpen}
        setConsentDialogOpen={setConsentDialogOpen}
        consentDate={consentDate}
        setConsentDate={setConsentDate}
        requireConsentInteraction={requireConsentInteraction}
        consentValid={consentValid}
      />
      {children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsProvider };
export default AnalyticsContext;
