// export type NavLink = {
//   // icon: React.ReactNode;
//   label: string;
//   href: string;
//   showInHeaderFrom: "sm" | "md" | "lg" | "xl" | "2xl" | "never";
//   minWidth: 600 | 900 | 1200 | 1500 | 1800 | 2100 | 2400 | 2700 | 3000;
// };

const NavLinks = [
  {
    // icon: <HomeIcon />,
    label: "Home",
    href: "/",
  },
  {
    // icon: <DocumentTextIcon />,
    label: "About",
    href: "/about",
  },
  {
    // icon: <BookOpenIcon />,
    label: "Blog",
    href: "/blog",
  },
  {
    //   // icon: <CodeIcon />,
    label: "Dev Tools",
    href: "https://dev.waleed.dev",
    //   showInHeaderFrom: "lg",
    //   minWidth: 1200,
  },
  // {
  //   // icon: <DocumentTextIcon />,
  //   label: "Resume",
  //   href: "/Resume",
  //   showInHeaderFrom: "lg",
  //   minWidth: 1200,
  // },
  {
    // icon: <DocumentTextIcon />,
    label: "Contact",
    href: "/contact",
  },
];

export default NavLinks;
